<script setup lang="ts">
import { tv } from "tailwind-variants"

const props = defineProps<{
  modelValue: boolean | undefined
  label?: string
  description?: string
  disabled?: boolean
  helpText?: string
  validationError?: string
}>()

const emit = defineEmits<(event: "update:modelValue", value: boolean) => void>()

function updateValue(value: boolean) {
  if (props.disabled) return

  emit("update:modelValue", value)
}

const classes = tv({
  base: "relative inline-flex h-6 w-10 bg-dusk-200 shrink-0 cursor-pointer rounded-full p-1 transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring focus-visible:ring-dusk-400 focus-visible:ring-offset-2",
  variants: {
    active: {
      true: "bg-forest-500",
    },
    disabled: {
      true: "opacity-50 cursor-not-allowed",
    },
  },
})
</script>

<template>
  <HeadlessSwitchGroup>
    <div>
      <div class="flex items-center gap-5">
        <HeadlessSwitch
          :model-value="modelValue"
          :class="classes({ active: Boolean(modelValue), disabled })"
          @update:model-value="updateValue($event)">
          <span class="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            :class="modelValue ? 'translate-x-4' : 'translate-x-0'"
            class="pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out" />
        </HeadlessSwitch>
        <HeadlessSwitchLabel
          v-if="label"
          class="flex cursor-pointer select-none flex-col gap-1">
          <p class="font-bold text-ebony-700">{{ label }}</p>
          <p v-if="description" class="text-dusk-600">
            {{ description }}
          </p>
        </HeadlessSwitchLabel>
        <HelpIcon v-if="helpText" :text="$props.helpText" />
      </div>
      <StatusMessage :error="validationError" status="error" />
    </div>
  </HeadlessSwitchGroup>
</template>
